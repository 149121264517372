import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllPlaces, deletePlace } from "../../../api/places";
import { ClipLoader } from "react-spinners";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import "../../../pages/Admin/AdminManagement/css/PlaceManagement.css";

function PlaceManagement() {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [placeToDelete, setPlaceToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const placesPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlaces() {
      try {
        const data = await getAllPlaces();
        setPlaces(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching places", error);
        setLoading(false);
      }
    }

    fetchPlaces();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredPlaces = places.filter((place) =>
    place.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastPlace = currentPage * placesPerPage;
  const indexOfFirstPlace = indexOfLastPlace - placesPerPage;
  const currentPlaces = filteredPlaces.slice(indexOfFirstPlace, indexOfLastPlace);

  const totalPages = Math.ceil(filteredPlaces.length / placesPerPage);

  const handleClick = (number) => {
    setCurrentPage(number);
  };

  const handleEditClick = (id) => {
    navigate(`/admin/places/edit/${id}`);
  };

  const handleOpenDeleteDialog = (placeId) => {
    setPlaceToDelete(placeId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setPlaceToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (placeToDelete) {
      try {
        await deletePlace(placeToDelete);
        setPlaces(places.filter((place) => place.id !== placeToDelete));
      } catch (error) {
        console.error("Error deleting place", error);
      }
    }
    handleCloseDeleteDialog();
  };

  const handleCreateClick = () => {
    navigate(`/admin/places/create`);
  };

  return (
    <div className="place-management-page">
      <h1 className="place-management-title">Manage Places</h1>
      <Button variant="contained" color="primary" onClick={handleCreateClick}>
        Add New Place
      </Button>

      <TextField
        label="Search Places"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <p>
        {filteredPlaces.length} place(s) found
      </p>

      {loading ? (
        <div className="loader-container">
          <ClipLoader color="#007bff" size={50} />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Main Photo</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPlaces.map((place) => (
                <TableRow key={place.id}>
                  <TableCell>{place.name}</TableCell>
                  <TableCell>{place.description}</TableCell>
                  <TableCell>{place.address}</TableCell>
                  <TableCell>
                    {place.photos && place.photos.length > 0 ? (
                      <img
                        src={
                          place.photos.find((photo) => photo.isMain)?.url ||
                          place.photos[0].url
                        }
                        alt={place.name}
                        style={{ width: "100px", height: "auto" }}
                      />
                    ) : (
                      "No photo available"
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(place.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(place.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className="pagination">
        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number + 1}
            onClick={() => handleClick(number + 1)}
            className={`page-item ${currentPage === number + 1 ? "active" : ""}`}
          >
            {number + 1}
          </button>
        ))}
      </div>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this place? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PlaceManagement;
