import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPlaceById } from "../../api/places";
import { createBooking, confirmBooking } from "../../api/bookings";
import {
  Container,
  Typography,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Paper,
  Dialog,
  TextField,
  Button,
  Box,
  InputAdornment,
  Alert,
  DialogActions,
} from "@mui/material";
import { ReactComponent as AzerbaijanFlag } from "../../assets/images/flag-for-flag-azerbaijan.svg";
import "./css/PlacePage.css";

function PlacePage() {
  const { id } = useParams();
  const [place, setPlace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bookingData, setBookingData] = useState({
    userName: "",
    phone: "",
    email: "",
  });
  const [confirmationCode, setConfirmationCode] = useState("");
  const [openBooking, setOpenBooking] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalError, setModalError] = useState("");

  useEffect(() => {
    async function fetchPlace() {
      try {
        const data = await getPlaceById(id);
        setPlace(data);
      } catch (error) {
        console.error("Error fetching place", error);
      } finally {
        setLoading(false);
      }
    }

    fetchPlace();
  }, [id]);

  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // const handleClose = () => {
  //   if (!confirmationCode || modalMessage) {
  //     setOpenConfirm(false);
  //   } else {
  //     if (
  //       window.confirm(
  //         "Are you sure you want to close? You haven't confirmed the booking yet."
  //       )
  //     ) {
  //       setOpenConfirm(false);
  //     }
  //   }
  // };

  const handleBookingChange = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
    validateField(e.target.name, e.target.value);
  };

  const validateField = (name, value) => {
    let error = "";

    if (!value) {
      error = "This field is required";
    } else {
      if (name === "phone") {
        const phonePattern = /^\d{9}$/;
        if (!phonePattern.test(value)) {
          error = "Phone number must be in the format XXXXXXXXX (9 digits)";
        }
      } else if (name === "email") {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(value)) {
          error = "Invalid email address";
        }
      }
    }

    setErrors({ ...errors, [name]: error });
    return error === "";
  };

  const handleBookingSubmit = async () => {
    const isValid =
      validateField("userName", bookingData.userName) &&
      validateField("phone", bookingData.phone) &&
      validateField("email", bookingData.email);

    if (!isValid) {
      return;
    }

    setLoadingConfirm(true);
    setModalMessage("");
    setModalError("");

    try {
      await createBooking({ ...bookingData, placeId: id });
      setOpenBooking(false);
      setOpenConfirm(true);
      setModalMessage(
        "Booking created successfully. Check your email for the confirmation code."
      );
    } catch (error) {
      console.error("Error creating booking", error.message);
      setModalError(
        error.message || "Failed to create booking. Please try again."
      );
    } finally {
      setLoadingConfirm(false);
    }
  };

  const handleCloseWithConfirmation = () => {
    if (window.confirm("Are you sure you want to close? You haven't confirmed the booking yet.")) {
      setOpenConfirm(false);
    }
  };

  const handleConfirmSubmit = async () => {
    setLoadingConfirm(true);
    setModalMessage("");
    setModalError("");

    try {
      await confirmBooking(confirmationCode.trim());
      setModalMessage("Booking confirmed successfully!");
      setTimeout(() => {
        setOpenConfirm(false);
      }, 2000);
    } catch (error) {
      console.error("Error confirming booking", error);
      if (error.response && error.response.data) {
        setModalError(
          `Failed to confirm booking: ${
            error.response.data.message || "Please try again."
          }`
        );
      } else {
        setModalError("Failed to confirm booking. Please try again.");
      }
    } finally {
      setLoadingConfirm(false);
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!place) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5">Place not found</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Card>
        {place.photos && place.photos.length > 0 && (
          <CardMedia
            component="img"
            height="300"
            image={place.photos[0]?.url}
            alt={place.name}
          />
        )}
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" gutterBottom>
              {place.name}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenBooking(true)}
              sx={{
                fontSize: "1.2rem",
                padding: "12px 24px",
                backgroundColor: "#007bff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Book Now
            </Button>
          </Box>
          <Typography variant="body1" gutterBottom>
            {place.description}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {place.address}
          </Typography>
          {place.contact && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Contact: {place.contact}
            </Typography>
          )}
        </CardContent>
      </Card>

      {place.photos && place.photos.length > 1 && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {place.photos.slice(1).map((photo) => (
            <Grid item xs={12} sm={6} md={4} key={photo.id}>
              <Paper
                onClick={() => handleClickOpen(photo.url)}
                sx={{ cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={photo.url}
                  alt={photo.description || "Place image"}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={open} onClose={handleCloseDialog}>
        <div className="dialog-image-container">
          <img
            src={selectedImage}
            alt="Enlarged view"
            className="dialog-image"
          />
          <button className="dialog-close-button" onClick={handleCloseDialog}>
            &times;
          </button>
        </div>
      </Dialog>

      <Dialog open={openBooking} onClose={() => setOpenBooking(false)}>
        <Box sx={{ p: 4 }}>
          <Typography variant="h6" gutterBottom>
            Booking Information
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Your Name"
            name="userName"
            value={bookingData.userName}
            onChange={handleBookingChange}
            error={!!errors.userName}
            helperText={errors.userName}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Phone"
            name="phone"
            value={bookingData.phone}
            onChange={handleBookingChange}
            error={!!errors.phone}
            helperText={errors.phone || "e.g., 501231212"}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <AzerbaijanFlag
                      style={{
                        width: "24px",
                        height: "16px",
                        marginRight: "8px",
                      }}
                    />
                  </InputAdornment>
                  <InputAdornment position="start">+994</InputAdornment>
                </>
              ),
              inputProps: { maxLength: 9 },
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={bookingData.email}
            onChange={handleBookingChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          {modalError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {modalError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleBookingSubmit}
            sx={{ mt: 2 }}
            disabled={loadingConfirm}
          >
            {loadingConfirm ? <CircularProgress size={24} /> : "Submit Booking"}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={openConfirm}
        onClose={handleCloseWithConfirmation}
        disableEscapeKeyDown // Отключаем закрытие по клавише "Escape"
        BackdropProps={{ onClick: () => null }} // Отключаем закрытие по клику на фон
      >
        <Box sx={{ p: 4 }}>
          <Typography variant="h6" gutterBottom>
            Enter Confirmation Code
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Confirmation Code"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            error={!!modalError}
            helperText={modalError}
          />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmSubmit}
              sx={{ mt: 2 }}
              disabled={loadingConfirm}
            >
              {loadingConfirm ? (
                <CircularProgress size={24} />
              ) : (
                "Confirm Booking"
              )}
            </Button>
            <Button
              onClick={handleCloseWithConfirmation}
              color="secondary"
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
          </DialogActions>
          {modalMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {modalMessage}
            </Alert>
          )}
        </Box>
      </Dialog>
    </Container>
  );
}

export default PlacePage;
