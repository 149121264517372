import { jwtDecode } from "jwt-decode";
import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/Account/";

const register = (username, email, fullName, password) => {
  return axios.post(API_URL + "SignUp", {
    username,
    email,
    fullName,
    password,
  });
};

const login = (emailOrUsername, password) => {
  return axios
    .post(API_URL + "SignIn", {
      emailOrUsername,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }
      return response.data;
    });
};

const changePassword = (usernameOrEmail, oldPassword, newPassword) => {
  return axios.post(API_URL + "ChangePassword/change-password", {
    usernameOrEmail,
    oldPassword,
    newPassword,
  });
};

const forgotPassword = (email) => {
  return axios.post(API_URL + "ForgotPassword/forgot-password", {
    email,
  });
};

const getLockoutStatus = (username) => {
  return axios.get(API_URL + `GetLockoutStatus/lockout-status/${username}`);
};

const logout = () => {
  localStorage.removeItem("token");
};

const getCurrentUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const roles =
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

      const userRoles = Array.isArray(roles) ? roles : [roles];

      return {
        username:
          decodedToken[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ], // Предполагается, что username хранится в этом поле
        roles: userRoles,
        isAdmin: userRoles.includes("Admin"),
        isMember: userRoles.includes("Member"),
      };
    } catch (error) {
      console.error("Error Decode Token:", error);
      return null;
    }
  }
  return null;
};

const getUserByUsername = async (username) => {
  try {
    const response = await axios.get(
      `${API_URL}GetUserByUsername/${username}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.Message || "Error fetching user data"
    );
  }
};

const isLoggedIn = () => {
  return !!localStorage.getItem("token");
};

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const getAllCategories = async () => {
  try {
    const response = await axios.get(
      "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/admin/Category/GetAll"
    );
    return response.data;
  } catch (error) {
    console.error("Get All Categories", error);
    throw error;
  }
};

const getCategoryById = async (id) => {
  try {
    const response = await axios.get(
      `https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/admin/Category/GetById/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching category by ID", error);
    throw error;
  }
};

export {
  register,
  login,
  changePassword,
  forgotPassword,
  getLockoutStatus,
  logout,
  getCurrentUser,
  getUserByUsername,
  isLoggedIn,
  getAuthHeader,
  getAllCategories,
  getCategoryById,
};
