import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { createPlace, getPlaceById, editPlace } from "../../../api/places";
import { getAllCategories } from "../../../api/categories";
import {
  TextField,
  Button,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Typography,
  IconButton,
  Radio,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Notification from "../../../components/Authentification/Notification";
import "./css/PlaceForm.css";

function PlaceForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [placeData, setPlaceData] = useState({
    name: "",
    description: "",
    address: "",
    categoryId: "",
    photos: [],
    mainPhotoId: null,
  });
  const [categories, setCategories] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ messages: [], type: "" });

  useEffect(() => {
    async function fetchCategories() {
      try {
        const data = await getAllCategories();
        setCategories(data);

        if (id) {
          setIsEdit(true);
          const placeData = await getPlaceById(id);

          const categoryExists = data.some(
            (category) => category.id === placeData.categoryId
          );

          if (categoryExists) {
            setPlaceData({
              ...placeData,
              categoryId: placeData.categoryId.toString(),
              photos: placeData.photos.map((photo) => ({
                ...photo,
                file: null,
              })),
              mainPhotoId:
                placeData.photos.find((photo) => photo.isMain)?.id || null,
            });
          } else {
            console.error(`Category with ID ${placeData.categoryId} not found`);
            setPlaceData({
              ...placeData,
              categoryId: "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching categories or place details", error);
      }
    }

    fetchCategories();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaceData({ ...placeData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const newPhotos = Array.from(e.target.files);
    const totalPhotos = placeData.photos.length + newPhotos.length;

    if (totalPhotos > 10) {
      setNotification({
        messages: ["Cannot upload more than 10 photos."],
        type: "error",
      });
      return;
    }

    const photosToAdd = newPhotos.map((file, index) => ({
      id: `new-${index}`,
      file,
      url: URL.createObjectURL(file),
      description: file.name,
    }));

    setPlaceData({
      ...placeData,
      photos: [...placeData.photos, ...photosToAdd],
    });
  };

  const handlePhotoDelete = (photoId) => {
    const photo = placeData.photos.find((p) => p.id === photoId);

    if (!photo) return;

    if (photo.isMain) {
      setNotification({
        messages: ["Cannot delete the main photo."],
        type: "error",
      });
      return;
    }

    if (placeData.photos.length <= 1) {
      setNotification({
        messages: ["Cannot delete the last photo."],
        type: "error",
      });
      return;
    }

    setPlaceData({
      ...placeData,
      photos: placeData.photos.filter((p) => p.id !== photoId),
    });
  };

  const handleMainPhotoChange = (photoId) => {
    setPlaceData({
      ...placeData,
      mainPhotoId: photoId,
    });
  };

  const createPlaceHandler = async (formData) => {
    try {
      const response = await createPlace(formData);

      if (
        response &&
        (response.success || (response.status >= 200 && response.status < 300))
      ) {
        setNotification({
          messages: ["Place created successfully!"],
          type: "success",
        });
        setTimeout(() => {
          navigate("/admin/places");
        }, 2000);
      } else if (response.data && response.data.errors) {
        const errorMessages = response.data.errors || [
          "Failed to create the place.",
        ];
        setNotification({
          messages: errorMessages,
          type: "error",
        });
      } else {
        console.error("Unexpected server response:", response);
        setNotification({
          messages: ["Unexpected response from server."],
          type: "error",
        });
      }
    } catch (error) {
      handleFormSubmitError(error);
    } finally {
      setLoading(false);
    }
  };

  const editPlaceHandler = async (id, formData) => {
    try {
      const response = await editPlace(id, formData);

      if (
        response &&
        (response.success || (response.status >= 200 && response.status < 300))
      ) {
        setNotification({
          messages: ["Place updated successfully!"],
          type: "success",
        });
        setTimeout(() => {
          navigate("/admin/places");
        }, 2000);
      } else if (response.data && response.data.errors) {
        const errorMessages = response.data.errors || [
          "Failed to update the place.",
        ];
        setNotification({
          messages: errorMessages,
          type: "error",
        });
      } else {
        console.error("Unexpected server response:", response);
        setNotification({
          messages: ["Unexpected response from server."],
          type: "error",
        });
      }
    } catch (error) {
      handleFormSubmitError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmitError = (error) => {
    let errorMessages = [];

    if (error.response && error.response.data) {
      const backendMessage =
        error.response.data.Message || error.response.data.message;

      if (backendMessage) {
        errorMessages.push(backendMessage);
      } else if (typeof error.response.data === "string") {
        errorMessages.push(error.response.data);
      } else if (error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          if (errors[key]) {
            errorMessages.push(`${key}: ${errors[key].join(", ")}`);
          }
        }
      }
    } else if (error.message) {
      errorMessages.push(error.message);
    } else {
      errorMessages.push("An unexpected error occurred.");
    }

    console.error("Error during place creation/update:", error);
    setNotification({
      messages: errorMessages,
      type: "error",
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", placeData.name);
    formData.append(
      "description",
      placeData.description || "Default description"
    );
    formData.append("address", placeData.address);
    formData.append("categoryId", parseInt(placeData.categoryId, 10));
    formData.append("mainPhotoId", placeData.mainPhotoId);

    placeData.photos.forEach((photo) => {
      if (photo.file) {
        formData.append("Photos", photo.file);
      }
    });

    if (isEdit) {
      await editPlaceHandler(id, formData);
    } else {
      await createPlaceHandler(formData);
    }
  };

  const handleBack = () => {
    navigate("/admin/places");
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {isEdit ? "Edit Place" : "Add New Place"}
      </Typography>
      <form onSubmit={handleFormSubmit} encType="multipart/form-data">
        <TextField
          label="Name"
          name="name"
          value={placeData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Description"
          name="description"
          value={placeData.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          name="address"
          value={placeData.address}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Category</InputLabel>
          <Select
            name="categoryId"
            value={placeData.categoryId}
            onChange={handleInputChange}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handlePhotoChange}
          style={{ marginTop: "16px" }}
        />
        {placeData.photos.length > 0 && (
          <div className="photos-preview">
            {placeData.photos.map((photo) => (
              <div key={photo.id} className="photo-container">
                <img
                  src={photo.url}
                  alt={photo.description || "Photo"}
                  style={{ margin: "10px", maxHeight: "150px" }}
                />
                <IconButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handlePhotoDelete(photo.id)}
                >
                  <DeleteIcon />
                </IconButton>
                <Radio
                  checked={placeData.mainPhotoId === photo.id}
                  onChange={() => handleMainPhotoChange(photo.id)}
                  value={photo.id}
                  name="mainPhoto"
                  inputProps={{ "aria-label": "Main Photo" }}
                />
              </div>
            ))}
          </div>
        )}

        <div className="button-container">
          <Button
            variant="contained"
            className="styled-button styled-button-primary"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : isEdit ? (
              "Update Place"
            ) : (
              "Create Place"
            )}
          </Button>
          <Button
            variant="outlined"
            className="styled-button styled-button-secondary"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </form>

      {notification.messages.length > 0 && (
        <Notification
          messages={notification.messages}
          type={notification.type}
        />
      )}
    </Container>
  );
}

export default PlaceForm;
