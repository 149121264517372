import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import {
  getAllUsers,
  deleteUser,
  signUp,
  getAllRoles,
  assignRole,
  deleteRole,
  removeRoleFromUser,
  forgotPassword,
  getUserByUsername,
  unlockUser,
  editUser,
  createRole,
} from "../../../../api/account";
import UserTable from "./UserTable";

import {
  CreateEditUserDialog,
  DeleteUserDialog,
  AssignRoleDialog,
  RemoveRoleDialog,
  ForgotPasswordDialog,
  CreateRoleDialog,
  DeleteRoleDialog,
} from "./Dialogs";
import "./css/UserManagement.css";

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false);
  const [openRemoveRoleDialog, setOpenRemoveRoleDialog] = useState(false);
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] =
    useState(false);
  const [openCreateRoleDialog, setOpenCreateRoleDialog] = useState(false);
  const [openDeleteRoleDialog, setOpenDeleteRoleDialog] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: "",
    email: "",
    fullName: "",
    password: "",
    isEmailConfirmed: false,
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [messageSeverity, setMessageSeverity] = useState("info");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);

  const isAdmin = true;
  const currentUser = users.find((user) => user.username === selectedUsername); 

  const fetchUsers = useCallback(async () => {
    try {
      const data = await getAllUsers();
      setUsers(data);
    } catch (error) {
      handleError("Failed to fetch users", error);
    }
  }, []);

  const fetchRoles = useCallback(async () => {
    try {
      const data = await getAllRoles();
      setRoles(data);
    } catch (error) {
      handleError("Failed to fetch roles", error);
    }
  }, []);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      await fetchUsers();
      await fetchRoles();
    };

    fetchData();
  }, [fetchUsers, fetchRoles]);

  // Error handling
  const handleError = (defaultMsg, error) => {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.errors?.join(", ") ||
      error.message ||
      defaultMsg;

    setMessage(errorMessage);
    setMessageSeverity("error");
  };

  // Handle page change for pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Search function to filter users
  const filteredUsers = users.filter((user) =>
    [user.username, user.email, user.fullName].some((field) =>
      field.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Paginate users
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  // Open the delete confirmation dialog
  const handleDeleteDialogOpen = (username) => {
    setSelectedUsername(username);
    setOpenDeleteDialog(true);
  };

  // Close the delete confirmation dialog
  const handleDeleteDialogClose = () => {
    setSelectedUsername(null);
    setOpenDeleteDialog(false);
  };

  // Open the create/edit user dialog
  const handleCreateEditDialogOpen = () => {
    setIsEdit(false);
    setNewUserData({
      username: "",
      email: "",
      fullName: "",
      password: "",
      isEmailConfirmed: false,
    });
    setSelectedUsername(null);
    setOpenCreateEditDialog(true);
  };

  // Open the edit user dialog
  const handleEditUserDialogOpen = (user) => {
    setIsEdit(true);
    setNewUserData({
      username: user.username,
      email: user.email,
      fullName: user.fullName,
      password: "",
      isEmailConfirmed: user.isEmailConfirmed,
    });
    setSelectedUsername(user.username);
    setOpenCreateEditDialog(true);
  };

  // Close the create/edit user dialog
  const handleCreateEditDialogClose = () => {
    setOpenCreateEditDialog(false);
  };

  // Open the assign role dialog
  const handleAssignRoleDialogOpen = (username) => {
    setSelectedUsername(username);
    setOpenAssignRoleDialog(true);
  };

  // Close the assign role dialog
  const handleAssignRoleDialogClose = () => {
    setOpenAssignRoleDialog(false);
    setSelectedRole("");
  };

  // Open the remove role dialog
  const handleRemoveRoleDialogOpen = (username) => {
    setSelectedUsername(username);
    setOpenRemoveRoleDialog(true);
  };

  // Close the remove role dialog
  const handleRemoveRoleDialogClose = () => {
    setOpenRemoveRoleDialog(false);
    setSelectedRole("");
  };

  // Open the forgot password dialog
  const handleForgotPasswordDialogOpen = () => {
    setOpenForgotPasswordDialog(true);
  };

  // Close the forgot password dialog
  const handleForgotPasswordDialogClose = () => {
    setOpenForgotPasswordDialog(false);
  };

  // Open the create role dialog
  const handleCreateRoleDialogOpen = () => {
    setOpenCreateRoleDialog(true);
  };

  // Close the create role dialog
  const handleCreateRoleDialogClose = () => {
    setOpenCreateRoleDialog(false);
    setNewRole("");
  };

  // Open the delete role dialog
  const handleDeleteRoleDialogOpen = () => {
    setOpenDeleteRoleDialog(true);
  };

  // Close the delete role dialog
  const handleDeleteRoleDialogClose = () => {
    setOpenDeleteRoleDialog(false);
    setSelectedRole("");
  };

  // Delete a user
  const handleDeleteUser = async () => {
    setIsLoading(true);
    try {
      if (isAdmin || selectedUsername === currentUser.username) {
        await deleteUser(selectedUsername);
        setUsers(users.filter((user) => user.username !== selectedUsername));
        handleDeleteDialogClose();
        setMessage("User deleted successfully");
        setMessageSeverity("success");
      } else {
        setMessage("You do not have permission to delete this user.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError("Failed to delete user", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle user creation or edit
  const handleCreateOrEditUser = async () => {
    const updatedData = {};

    if (
      newUserData.email !==
      users.find((user) => user.username === selectedUsername)?.email
    ) {
      updatedData.email = newUserData.email;
    }

    if (
      newUserData.fullName !==
      users.find((user) => user.username === selectedUsername)?.fullName
    ) {
      updatedData.fullName = newUserData.fullName;
    }

    if (newUserData.password) {
      updatedData.password = newUserData.password;
    }

    if (Object.keys(updatedData).length === 0) {
      setMessage("No changes detected.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      if (isAdmin || selectedUsername === currentUser.username) {
        if (isEdit) {
          await editUser(selectedUsername, updatedData);
          setMessage("User updated successfully");
        } else {
          await signUp(newUserData);
          setMessage("User created successfully");
        }
        setMessageSeverity("success");
        fetchUsers();
      } else {
        setMessage("You do not have permission to edit this user.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError(
        isEdit ? "Failed to update user" : "Failed to create user",
        error
      );
    } finally {
      setIsLoading(false);
      handleCreateEditDialogClose();
    }
  };

  // Assign a role to a user
  const handleAssignRole = async () => {
    if (!selectedRole) {
      setMessage("Please select a role to assign.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      if (isAdmin) {
        await assignRole({
          username: selectedUsername,
          role: selectedRole,
        });
        handleAssignRoleDialogClose();
        setMessage("Role assigned successfully");
        setMessageSeverity("success");
        fetchUsers();
      } else {
        setMessage("You do not have permission to assign roles.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError("Failed to assign role", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Remove a role from a user
  const handleRemoveRole = async () => {
    if (!selectedRole) {
      setMessage("Please select a role to remove.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      if (isAdmin) {
        await removeRoleFromUser({
          username: selectedUsername,
          role: selectedRole,
        });
        handleRemoveRoleDialogClose();
        setMessage("Role removed successfully");
        setMessageSeverity("success");
        fetchUsers();
      } else {
        setMessage("You do not have permission to remove roles.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError("Failed to remove role", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Forgot password
  const handleForgotPassword = async () => {
    if (!forgotPasswordEmail.trim()) {
      setMessage("Email is required for password reset.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      await forgotPassword(forgotPasswordEmail);
      handleForgotPasswordDialogClose();
      setMessage("Password reset email sent");
      setMessageSeverity("success");
    } catch (error) {
      handleError("Failed to send password reset email", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Create a new role
  const handleCreateRole = async () => {
    if (!newRole.trim()) {
      setMessage("Role name cannot be empty.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      if (isAdmin) {
        await createRole({ role: newRole });
        setRoles([...roles, newRole]);
        handleCreateRoleDialogClose();
        setMessage("Role created successfully");
        setMessageSeverity("success");
        setNewRole("");
        fetchRoles();
      } else {
        setMessage("You do not have permission to create roles.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError("Failed to create role", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle role deletion
  const handleDeleteRole = async () => {
    if (!selectedRole) {
      setMessage("Please select a role to delete.");
      setMessageSeverity("warning");
      return;
    }

    setIsLoading(true);
    try {
      if (isAdmin) {
        await deleteRole(selectedRole);
        setRoles(roles.filter((role) => role !== selectedRole));
        handleDeleteRoleDialogClose();
        setMessage("Role deleted successfully");
        setMessageSeverity("success");
        fetchRoles();
      } else {
        setMessage("You do not have permission to delete roles.");
        setMessageSeverity("error");
      }
    } catch (error) {
      handleError("Failed to delete role", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get user details
  const handleGetUserByUsername = async (username) => {
    setIsLoading(true);
    try {
      const user = await getUserByUsername(username);
      const userDetails = `
        Username: ${user.username}\n
        Email: ${user.email}\n
        Full Name: ${user.fullName}\n
        Roles: ${
          user.roles && user.roles.length > 0
            ? user.roles.join(", ")
            : "No roles"
        }\n
        Email Confirmed: ${user.isEmailConfirmed ? "Yes" : "No"}\n
        Created Date: ${new Date(user.createdDate).toLocaleDateString()}\n
        Last Password Reset: ${
          user.lastPasswordResetRequest
            ? new Date(user.lastPasswordResetRequest).toLocaleDateString()
            : "Never"
        }\n
        Password Reset Count: ${user.passwordResetRequestCount}\n
      `;

      setMessage(userDetails.trim());
      setMessageSeverity("info");
    } catch (error) {
      handleError("Failed to get user details", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Unlock user
  const handleUnlockUser = async (username) => {
    setIsLoading(true);
    try {
      await unlockUser(username);
      setMessage("User unlocked successfully");
      setMessageSeverity("success");
      fetchUsers();
    } catch (error) {
      handleError("Failed to unlock user", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="container">
      <Typography variant="h4" align="center" className="header">
        User Management
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        className="search-and-buttons"
      >
        <Grid item className="search-box">
          <TextField
            label="Search Users"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Users Per Page"
            type="number"
            variant="outlined"
            fullWidth
            value={usersPerPage}
            onChange={(e) => {
              const value = Math.max(1, parseInt(e.target.value, 10) || 1);
              setUsersPerPage(value);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          container
          spacing={2}
          justifyContent="flex-end"
          className="button-group"
        >
          <Grid item>
            <Button
              variant="contained"
              className="button button-blue"
              onClick={handleCreateEditDialogOpen}
            >
              Add New User
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="button button-green"
              onClick={handleCreateRoleDialogOpen}
            >
              Create Role
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="button button-purple"
              onClick={handleForgotPasswordDialogOpen}
            >
              Forgot Password
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="button button-red"
              onClick={handleDeleteRoleDialogOpen}
            >
              Delete Role
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <UserTable
        users={users}
        paginatedUsers={paginatedUsers}
        handleEditUserDialogOpen={handleEditUserDialogOpen}
        handleAssignRoleDialogOpen={handleAssignRoleDialogOpen}
        handleRemoveRoleDialogOpen={handleRemoveRoleDialogOpen}
        handleUnlockUser={handleUnlockUser}
        handleGetUserByUsername={handleGetUserByUsername}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        isLoading={isLoading}
        isAdmin={isAdmin}
      />

      <Pagination
        count={Math.ceil(filteredUsers.length / usersPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        className="pagination"
      />

      <CreateEditUserDialog
        open={openCreateEditDialog}
        isEdit={isEdit}
        newUserData={newUserData}
        setNewUserData={setNewUserData}
        handleClose={handleCreateEditDialogClose}
        handleSubmit={handleCreateOrEditUser}
        isLoading={isLoading}
      />

      <DeleteUserDialog
        open={openDeleteDialog}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteUser}
        isLoading={isLoading}
      />

      <AssignRoleDialog
        open={openAssignRoleDialog}
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        handleClose={handleAssignRoleDialogClose}
        handleSubmit={handleAssignRole}
        isLoading={isLoading}
      />

      <RemoveRoleDialog
        open={openRemoveRoleDialog}
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        handleClose={handleRemoveRoleDialogClose}
        handleSubmit={handleRemoveRole}
        isLoading={isLoading}
      />

      <ForgotPasswordDialog
        open={openForgotPasswordDialog}
        forgotPasswordEmail={forgotPasswordEmail}
        setForgotPasswordEmail={setForgotPasswordEmail}
        handleClose={handleForgotPasswordDialogClose}
        handleSubmit={handleForgotPassword}
        isLoading={isLoading}
      />

      <CreateRoleDialog
        open={openCreateRoleDialog}
        newRole={newRole}
        setNewRole={setNewRole}
        handleClose={handleCreateRoleDialogClose}
        handleSubmit={handleCreateRole}
        isLoading={isLoading}
      />

      <DeleteRoleDialog
        open={openDeleteRoleDialog}
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        handleClose={handleDeleteRoleDialogClose}
        handleSubmit={handleDeleteRole}
        isLoading={isLoading}
      />

      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
      >
        <Alert
          onClose={() => setMessage(null)}
          severity={messageSeverity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UserManagement;
