import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { getAllSliders, deleteSlider } from "../../../api/sliders";
import Notification from "../../../components/Authentification/Notification";

function SliderManagement() {
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ messages: [], type: "" });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const slidersPerPage = 6;

  useEffect(() => {
    fetchSliders();
  }, []);

  const fetchSliders = async () => {
    try {
      setLoading(true);
      const slidersData = await getAllSliders();
      setSliders(slidersData);
    } catch (error) {
      setNotification({
        messages: ["Failed to fetch sliders."],
        type: "error",
      });
      console.error("Error fetching sliders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSlider = async (sliderId) => {
    try {
      await deleteSlider(sliderId);
      setNotification({
        messages: ["Slider deleted successfully!"],
        type: "success",
      });
      fetchSliders(); 
    } catch (error) {
      setNotification({
        messages: [error.message],
        type: "error",
      });
      console.error("Error deleting slider:", error);
    }
  };

  const handleOpenDeleteDialog = (sliderId) => {
    setSliderToDelete(sliderId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSliderToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (sliderToDelete) {
      handleDeleteSlider(sliderToDelete);
    }
    handleCloseDeleteDialog();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Сброс на первую страницу при поиске
  };

  const filteredSliders = sliders.filter(
    (slider) =>
      slider.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      slider.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastSlider = currentPage * slidersPerPage;
  const indexOfFirstSlider = indexOfLastSlider - slidersPerPage;
  const currentSliders = filteredSliders.slice(
    indexOfFirstSlider,
    indexOfLastSlider
  );

  const totalPages = Math.ceil(filteredSliders.length / slidersPerPage);

  const handleClick = (number) => {
    setCurrentPage(number);
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Slider Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        component={Link}
        to="/admin/sliders/create"
        sx={{ mb: 3 }}
      >
        Add New Slider
      </Button>

      <TextField
        label="Search Sliders"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 4 }}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={60} />
        </div>
      ) : (
        <Grid container spacing={3}>
          {currentSliders.map((slider) => (
            <Grid item xs={12} sm={6} md={4} key={slider.id}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={slider.imageUrl}
                  alt={slider.title}
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography variant="h6" gutterBottom>
                  {slider.title}
                </Typography>
                <Typography variant="body2">{slider.description}</Typography>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  component={Link}
                  to={`/admin/sliders/edit/${slider.id}`}
                  sx={{ mt: 2 }}
                >
                  Edit
                </Button>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => handleOpenDeleteDialog(slider.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {filteredSliders.length > slidersPerPage && (
        <div className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <Button
              key={number + 1}
              onClick={() => handleClick(number + 1)}
              className={`page-item ${
                currentPage === number + 1 ? "active" : ""
              }`}
              variant={currentPage === number + 1 ? "contained" : "outlined"}
              sx={{ mx: 1 }}
            >
              {number + 1}
            </Button>
          ))}
        </div>
      )}

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this slider? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {notification.messages.length > 0 && (
        <Notification
          messages={notification.messages}
          type={notification.type}
        />
      )}
    </Container>
  );
}

export default SliderManagement;
