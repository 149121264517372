import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";
import { getAllCategories, deleteCategory } from "../../../api/categories";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Notification from "../../../components/Authentification/Notification";

function CategoryManagement() {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [notification, setNotification] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const data = await getAllCategories();
      setCategories(data);
      setFilteredCategories(data);
    } catch (error) {
      console.error("Error fetching categories", error);
      setNotification({
        messages: ["Error fetching categories"],
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    const filtered = categories.filter((category) =>
      category.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const handleDeleteDialogOpen = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setSelectedCategoryId(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await deleteCategory(selectedCategoryId);
      setCategories(
        categories.filter((category) => category.id !== selectedCategoryId)
      );
      setFilteredCategories(
        filteredCategories.filter(
          (category) => category.id !== selectedCategoryId
        )
      );
      setNotification({
        messages: ["Category deleted successfully!"],
        type: "success",
      });
      handleDeleteDialogClose();
    } catch (error) {
      console.error(
        "Cannot delete category. A minimum of 3 categories must be maintained",
        error
      );
      setNotification({
        messages: [
          "Cannot delete category. A minimum of 3 categories must be maintained",
        ],
        type: "error",
      });
    }
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Category Management
      </Typography>
      <TextField
        label="Search Categories"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 4 }}
      />

      <Typography variant="subtitle1" gutterBottom>
        {filteredCategories.length} category(s) found
      </Typography>

      {loading ? (
        <Container sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Container>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Main Photo</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCategories.map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.name}</TableCell>
                  <TableCell>{category.description}</TableCell>
                  <TableCell>
                    <img
                      src={
                        category.photos.find((photo) => photo.isMain)?.url ||
                        (category.photos.length > 0
                          ? category.photos[0].url
                          : "")
                      }
                      alt={category.name}
                      style={{ width: "100px", height: "auto" }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      component={Link}
                      to={`/admin/categories/edit/${category.id}`}
                      aria-label={`edit-category-${category.id}`}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteDialogOpen(category.id)}
                      aria-label={`delete-category-${category.id}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/admin/categories/create"
        sx={{ mt: 2 }}
      >
        Add New Category
      </Button>

      <Dialog
        open={open}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {notification.messages.length > 0 && (
        <Notification
          messages={notification.messages}
          type={notification.type}
        />
      )}
    </Container>
  );
}

export default CategoryManagement;
