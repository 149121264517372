import React, { useState, useEffect, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Fade,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  InputBase,
  InputAdornment,
  Paper,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { FaArrowUp, FaUserCircle } from "react-icons/fa";
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout, getCurrentUser } from "../../api/auth";
import { searchQuery } from "../../api/search";
import "./Header.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(() => {
    const currentUser = getCurrentUser();
    console.log("Current user on initial load:", currentUser);
    return currentUser ? currentUser : null;
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const logoRef = React.useRef();

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    setScrolled(offset > 50);

    if (offset > lastScrollTop) {
      logoRef.current.classList.remove("scroll-up");
      logoRef.current.classList.add("scroll-down");
    } else {
      logoRef.current.classList.remove("scroll-down");
      logoRef.current.classList.add("scroll-up");
    }
    setLastScrollTop(offset);
  }, [lastScrollTop]);

  const handleLogout = () => {
    logout();
    setUser(null);
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const currentUser = getCurrentUser();
    setUser(currentUser ? currentUser : null);

    if (currentUser) {
      if (currentUser.isAdmin && location.pathname === "/login") {
        navigate("/admin-panel");
      } else if (!currentUser.isAdmin && location.pathname === "/login") {
        navigate("/");
      }
    }
  }, [location, navigate]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSearchChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      try {
        const results = await searchQuery(value);
        console.log("Search results:", results);
        setSearchResults(results);
        setOpenSearchResults(true);
      } catch (error) {
        console.error(
          "Error fetching search results:",
          error.response || error.message
        );
      }
    } else {
      setSearchResults([]);
      setOpenSearchResults(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
    setOpenSearchResults(false);
  };

  const handleResultClick = (result) => {
    if (result.category) {
      if (result.id) {
        navigate(`/categories/${result.id}`);
      } else {
        console.error("Invalid category ID:", result.id);
      }
    } else if (result.place) {
      if (result.id) {
        navigate(`/places/${result.id}`);
      } else {
        console.error("Invalid place ID:", result.id);
      }
    }
    setOpenSearchResults(false);
  };

  const menuItems = (
    <List
      sx={{ width: 250 }}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <ListItem
        button
        component={Link}
        to="/"
        selected={location.pathname === "/"}
      >
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/all-categories"
        selected={location.pathname === "/all-categories"}
      >
        <ListItemText primary="Categories" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/about"
        selected={location.pathname === "/about"}
      >
        <ListItemText primary="About" />
      </ListItem>
      {user && user.isAdmin && (
        <ListItem
          button
          component={Link}
          to="/admin-panel"
          selected={location.pathname === "/admin-panel"}
        >
          <ListItemText primary="Admin Panel" />
        </ListItem>
      )}
      {user ? (
        <>
          <ListItem button component={Link} to="/account-setting">
            <ListItemText primary="My Account" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="Log Out" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem
            button
            component={Link}
            to="/login"
            selected={location.pathname === "/login"}
          >
            <ListItemText primary="Log in" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/register"
            selected={location.pathname === "/register"}
          >
            <ListItemText primary="Register" />
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        className={scrolled ? "scrolled" : ""}
        sx={{ backgroundColor: "#283593", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            {menuItems}
          </Drawer>
          <Link to="/" className="logo-link" ref={logoRef}>
            <img
              src={require("../../assets/images/BookItAllLogo-removebg-previewYellow.png")}
              alt="Logo"
              className="logo-image"
            />
          </Link>
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: 2,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              fontFamily: "'Roboto', sans-serif",
              transition: "all 0.5s ease-in-out",
            }}
            className="logo-container"
          >
            <Link to="/" className="logo-text">
              <span className="logo-part1" style={{ color: "#FFC107" }}>
                B
              </span>
              <span className="logo-part1" style={{ color: "#FFC107" }}>
                o
              </span>
              <span className="logo-part1" style={{ color: "#FFC107" }}>
                o
              </span>
              <span className="logo-part1" style={{ color: "#FFC107" }}>
                k
              </span>
              <span
                className="logo-part2"
                style={{ color: "#FFFFFF", marginLeft: "4px" }}
              >
                I
              </span>
              <span className="logo-part2" style={{ color: "#FFFFFF" }}>
                t
              </span>
              <span className="logo-part2" style={{ color: "#FFFFFF" }}>
                A
              </span>
              <span className="logo-part2" style={{ color: "#FFFFFF" }}>
                l
              </span>
              <span className="logo-part2" style={{ color: "#FFFFFF" }}>
                l
              </span>
            </Link>
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={handleSearchChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClearSearch}
                    sx={{ visibility: searchTerm ? "visible" : "hidden" }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            {openSearchResults && (
              <Paper elevation={3} className="search-results">
                {searchResults.categories &&
                  searchResults.categories.length > 0 &&
                  searchResults.categories.map((result, index) => (
                    <MenuItem
                      key={result.id || index}
                      onClick={() =>
                        handleResultClick({ category: true, id: result.id })
                      }
                    >
                      {result.name}
                    </MenuItem>
                  ))}

                {searchResults.places &&
                  searchResults.places.length > 0 &&
                  searchResults.places.map((result, index) => (
                    <MenuItem
                      key={result.id || index}
                      onClick={() =>
                        handleResultClick({ place: true, id: result.id })
                      }
                    >
                      {result.name}
                    </MenuItem>
                  ))}
              </Paper>
            )}
          </Search>

          {!isMobile && (
            <Box sx={{ display: "flex" }}>
              {user ? (
                <>
                  {user.isAdmin && (
                    <Link
                      to="/admin-panel"
                      className={`nav-link ${
                        location.pathname === "/admin-panel"
                          ? "active-link"
                          : ""
                      }`}
                    >
                      Admin Panel
                    </Link>
                  )}
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <FaUserCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/my-account"
                    >
                      My Account
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleLogout();
                      }}
                    >
                      Log Out
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className={`nav-link ${
                      location.pathname === "/login" ? "active-link" : ""
                    }`}
                  >
                    Log in
                  </Link>
                  <Link
                    to="/register"
                    className={`nav-link ${
                      location.pathname === "/register" ? "active-link" : ""
                    }`}
                  >
                    Register
                  </Link>
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <div
        className="top-btn"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <FaArrowUp size={24} />
      </div>
      <div className="main-content">{/* Your main content here */}</div>
    </>
  );
}

export default Header;
