import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { login, getCurrentUser } from "../../api/auth"; 
import Notification from "./Notification";
import "./css/Login.css";
import { CircularProgress } from "@mui/material";

function Login() {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);  // Для показа/скрытия пароля
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await login(emailOrUsername, password);
      if (response.success) {
        setTimeout(() => {
          const user = getCurrentUser(); 
          if (user && user.roles.includes("Admin")) {
            navigate("/admin");
          } else {
            navigate("/");
          }
          window.location.reload();
        }, 3000);
      } else {
        setErrorMessages([response.message]);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessages([
        "An error occurred while logging in. Please try again.",
      ]);
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      {loading && (
        <div className="loading-overlay">
          <CircularProgress size={80} />
        </div>
      )}
      {errorMessages.length > 0 && <Notification messages={errorMessages} />}
      <form onSubmit={handleLogin} className="login-form">
        <div className="form-group">
          <label>Email or Username</label>
          <input
            type="text"
            value={emailOrUsername}
            onChange={(e) => setEmailOrUsername(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <div className="password-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Login"}
        </button>
        <div className="forgot-password">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
