import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import "./Slider.css";
import { CircularProgress, Box } from "@mui/material";

function Slider() {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(
          "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/admin/Slider/GetAll"
        );
        setSlides(response.data);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Sliders Not Found:", error);
        setLoading(false);
      }
    };

    fetchSlides();
  }, []);

  return (
    <div className="slider-container">
      {loading ? (
        <Box className="loading-overlay">
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Carousel>
          {slides.map((slide) => (
            <Carousel.Item key={slide.id}>
              <img
                className="d-block w-100"
                src={slide.imageUrl}
                alt={slide.title}
              />
              <Carousel.Caption className="animated-caption">
                <h3>{slide.title}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default Slider;
