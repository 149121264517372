import React from "react";
import { Container, Typography, Grid, Paper, Box, Button } from "@mui/material";
import { getCurrentUser } from "../../api/auth";
import { Navigate, Link } from "react-router-dom";
import {
  People as PeopleIcon,
  Category as CategoryIcon,
  Slideshow as SlideshowIcon,
  Place as PlaceIcon,
  Book as BookingIcon,
} from "@mui/icons-material";
import "./css/AdminPanel.css";

function AdminPanel() {
  const currentUser = getCurrentUser();

  if (!currentUser || !currentUser.isAdmin) {
    return <Navigate to="/" />;
  }

  const adminSections = [
    {
      title: "Users",
      description: "Manage users: view, edit, or delete users.",
      link: "/admin/users",
      buttonLabel: "Go to User Management",
      icon: <PeopleIcon sx={{ fontSize: 60 }} />,
    },
    {
      title: "Categories",
      description: "View and manage categories.",
      link: "/admin/categories",
      buttonLabel: "Go to Category Management",
      icon: <CategoryIcon sx={{ fontSize: 60 }} />,
    },
    {
      title: "Sliders",
      description:
        "Manage sliders for your homepage. Add, edit, or remove sliders.",
      link: "/admin/sliders",
      buttonLabel: "Go to Slider Management",
      icon: <SlideshowIcon sx={{ fontSize: 60 }} />,
    },
    {
      title: "Places",
      description: "Manage places: add new places or update existing places.",
      link: "/admin/places",
      buttonLabel: "Go to Place Management",
      icon: <PlaceIcon sx={{ fontSize: 60 }} />,
    },
    {
      title: "Bookings",
      description: "Manage bookings: view, confirm, or delete bookings.",
      link: "/admin/bookings",
      buttonLabel: "Go to Booking Management",
      icon: <BookingIcon sx={{ fontSize: 60 }} />,
    },
  ];

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Admin Panel
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Welcome to the admin panel. Here you can manage users, categories,
        sliders, places, and bookings.
      </Typography>
      <Grid container spacing={4}>
        {adminSections.map((section, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper
              className="admin-section"
              elevation={3}
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Box padding={3} textAlign="center">
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  {section.icon}
                </Box>
                <Typography variant="h6" gutterBottom>
                  {section.title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {section.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={section.link}
                  sx={{
                    mt: 2,
                    color: "white",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#0d47a1",
                    },
                  }}
                >
                  {section.buttonLabel}
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AdminPanel;
