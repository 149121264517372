import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./css/ResetPassword.css";

const ResetPassword = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 4000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="reset-password-success">
      <h1>Password Reset Successful</h1>
      <p>
        Your password has been successfully reset. You will be redirected to the
        login page shortly.
      </p>
    </div>
  );
};

export default ResetPassword;
