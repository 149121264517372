import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

export const CreateEditUserDialog = ({
  open,
  isEdit,
  newUserData,
  setNewUserData,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{isEdit ? "Edit User" : "Create User"}</DialogTitle>
    <DialogContent>
      <TextField
        label="Username"
        fullWidth
        margin="normal"
        required
        value={newUserData.username}
        onChange={(e) =>
          setNewUserData({ ...newUserData, username: e.target.value })
        }
        disabled={isEdit}
        error={!newUserData.username.trim()}
        helperText={!newUserData.username.trim() && "Username is required"}
      />
      <TextField
        label="Email"
        fullWidth
        margin="normal"
        required
        value={newUserData.email}
        onChange={(e) =>
          setNewUserData({ ...newUserData, email: e.target.value })
        }
        error={!newUserData.email.trim()}
        helperText={!newUserData.email.trim() && "Email is required"}
      />
      <TextField
        label="Full Name"
        fullWidth
        margin="normal"
        required
        value={newUserData.fullName}
        onChange={(e) =>
          setNewUserData({ ...newUserData, fullName: e.target.value })
        }
        error={!newUserData.fullName.trim()}
        helperText={!newUserData.fullName.trim() && "Full name is required"}
      />

      {!isEdit && (
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          required
          value={newUserData.password}
          onChange={(e) =>
            setNewUserData({ ...newUserData, password: e.target.value })
          }
          error={!newUserData.password.trim()}
          helperText={!newUserData.password.trim() && "Password is required"}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : isEdit ? (
          "Update"
        ) : (
          "Create"
        )}
      </Button>
    </DialogActions>
  </Dialog>
);

export const DeleteUserDialog = ({
  open,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete this user? This action cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        color="secondary"
        autoFocus
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Delete"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const AssignRoleDialog = ({
  open,
  roles,
  selectedRole,
  setSelectedRole,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Assign Role</DialogTitle>
    <DialogContent>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Role</InputLabel>
        <Select
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          error={!selectedRole}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {!selectedRole && (
          <Typography color="error">Please select a role</Typography>
        )}
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : "Assign"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const RemoveRoleDialog = ({
  open,
  roles,
  selectedRole,
  setSelectedRole,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Remove Role</DialogTitle>
    <DialogContent>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Role</InputLabel>
        <Select
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          error={!selectedRole}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {!selectedRole && (
          <Typography color="error">Please select a role</Typography>
        )}
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : "Remove"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const ForgotPasswordDialog = ({
  open,
  forgotPasswordEmail,
  setForgotPasswordEmail,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Forgot Password</DialogTitle>
    <DialogContent>
      <TextField
        label="Email"
        fullWidth
        margin="normal"
        required
        value={forgotPasswordEmail}
        onChange={(e) => setForgotPasswordEmail(e.target.value)}
        error={!forgotPasswordEmail.trim()}
        helperText={!forgotPasswordEmail.trim() && "Email is required"}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : "Send"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const EditSelfDialog = ({
  open,
  selfEditData,
  setSelfEditData,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Edit Self</DialogTitle>
    <DialogContent>
      <TextField
        label="Full Name"
        fullWidth
        margin="normal"
        value={selfEditData.fullName}
        onChange={(e) =>
          setSelfEditData({ ...selfEditData, fullName: e.target.value })
        }
        error={!selfEditData.fullName.trim() && selfEditData.fullName !== ""}
        helperText={
          !selfEditData.fullName.trim() &&
          selfEditData.fullName !== "" &&
          "Full name is required"
        }
      />
      <TextField
        label="Username"
        fullWidth
        margin="normal"
        value={selfEditData.username}
        onChange={(e) =>
          setSelfEditData({ ...selfEditData, username: e.target.value })
        }
        error={!selfEditData.username.trim() && selfEditData.username !== ""}
        helperText={
          !selfEditData.username.trim() &&
          selfEditData.username !== "" &&
          "Username is required"
        }
      />
      <TextField
        label="Current Password"
        type="password"
        fullWidth
        margin="normal"
        value={selfEditData.currentPassword}
        onChange={(e) =>
          setSelfEditData({
            ...selfEditData,
            currentPassword: e.target.value,
          })
        }
      />
      <TextField
        label="New Password"
        type="password"
        fullWidth
        margin="normal"
        value={selfEditData.newPassword}
        onChange={(e) =>
          setSelfEditData({ ...selfEditData, newPassword: e.target.value })
        }
      />
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleClose}
        color="primary"
        variant="outlined"
        sx={{ borderRadius: 2 }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        disabled={isLoading}
        sx={{ borderRadius: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Save"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const CreateRoleDialog = ({
  open,
  newRole,
  setNewRole,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Create Role</DialogTitle>
    <DialogContent>
      <TextField
        label="Role Name"
        fullWidth
        margin="normal"
        required
        value={newRole}
        onChange={(e) => setNewRole(e.target.value)}
        error={!newRole.trim()}
        helperText={!newRole.trim() && "Role name is required"}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : "Create"}
      </Button>
    </DialogActions>
  </Dialog>
);

export const DeleteRoleDialog = ({
  open,
  roles,
  selectedRole,
  setSelectedRole,
  handleClose,
  handleSubmit,
  isLoading,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Delete Role</DialogTitle>
    <DialogContent>
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Role</InputLabel>
        <Select
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          error={!selectedRole}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {!selectedRole && (
          <Typography color="error">Please select a role</Typography>
        )}
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : "Delete"}
      </Button>
    </DialogActions>
  </Dialog>
);
