import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/Booking";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export const getAllBookings = async () => {
  try {
    const response = await axios.get(`${API_URL}/GetAllBookings`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching bookings", error);
    throw new Error("Failed to fetch bookings");
  }
};

export const getBookingById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/GetBookingById/${id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching booking by ID", error);
    throw new Error("Failed to fetch booking details");
  }
};

export const createBooking = async (bookingData) => {
  try {
    const response = await axios.post(`${API_URL}/CreateBooking`, bookingData, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data
        ? error.response.data
        : "Failed to create booking";
    console.error("Error creating booking", errorMessage);
    throw new Error(errorMessage);
  }
};

export const updateBooking = async (id, bookingData) => {
  try {
    const response = await axios.put(
      `${API_URL}/UpdateBooking/${id}`,
      bookingData,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating booking", error);
    throw new Error("Failed to update booking");
  }
};

export const deleteBooking = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/DeleteBooking/${id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting booking", error);
    throw new Error("Failed to delete booking");
  }
};

export const confirmBooking = async (confirmationCode) => {
  try {
    const response = await axios.post(
      `${API_URL}/ConfirmBooking/confirm`,
      null,
      {
        params: { confirmationCode },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming booking", error);
    throw new Error("Failed to confirm booking");
  }
};

export const getBookingsForDay = async (date) => {
  try {
    const response = await axios.get(
      `${API_URL}/GetAllBookingsForDay/date/${date}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bookings for day", error);
    throw new Error("Failed to fetch bookings for day");
  }
};
