import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const apiRequest = async (method, endpoint, data = null, config = {}) => {
  try {
    const response = await axios({
      method,
      url: `${API_URL}${endpoint}`,
      data,
      headers: { ...getAuthHeader(), ...config.headers },
      ...config,
    });
    return response.data;
  } catch (error) {
    let errorMessage = "Request failed";

    if (error.response && error.response.data) {
      const responseData = error.response.data;
      if (responseData.Message) {
        errorMessage = responseData.Message;
      } else if (responseData.message) {
        errorMessage = responseData.message;
      } else if (responseData.error) {
        errorMessage = responseData.error;
      } else if (responseData.errors && Array.isArray(responseData.errors)) {
        errorMessage = responseData.errors.join(", ");
      }
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error(
      "API Request Error:",
      errorMessage,
      error.response?.data || error
    );

    throw new Error(
      `Error: ${errorMessage}. Status Code: ${error.response?.status}`
    );
  }
};

export const signUp = async (userData) => {
  if (
    !userData ||
    !userData.username ||
    !userData.email ||
    !userData.password
  ) {
    throw new Error("User data must include username, email, and password.");
  }

  try {
    return await apiRequest("post", "/Account/SignUp", userData);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      throw new Error(Object.values(validationErrors).join(' '));
    } else {
      throw new Error("An unexpected error occurred during sign up.");
    }
  }
};

export const editUser = async (username, userData) => {
  if (!username) {
    throw new Error("Username is required to edit a user.");
  }
  if (!userData) {
    throw new Error("User data is required to edit a user.");
  }

  const dataToSend = {};

  if (userData.username && userData.username !== "")
    dataToSend.username = userData.username;
  if (userData.email && userData.email !== "")
    dataToSend.email = userData.email;
  if (userData.fullName && userData.fullName !== "")
    dataToSend.fullName = userData.fullName;

  if (Object.keys(dataToSend).length === 0) {
    throw new Error("No fields have been changed.");
  }

  return apiRequest(
    "post",
    `/admin/Account/EditUser?username=${username}`,
    dataToSend
  );
};

export const getLockoutStatus = async (username) => {
  if (!username) {
    throw new Error("Username is required to get lockout status.");
  }
  return apiRequest(
    "get",
    `/Account/GetLockoutStatus/lockout-status/${username}`
  );
};

export const getAllUsers = async (page = 1, pageSize = 10000) => {
  return apiRequest(
    "get",
    `/admin/Account/GetAllUsers?page=${page}&pageSize=${pageSize}`
  );
};

export const getUserByUsername = async (username) => {
  if (!username) {
    throw new Error("Username is required to fetch user details.");
  }
  return apiRequest("get", `/Account/GetUserByUsername/${username}`);
};

export const unlockUser = async (username) => {
  if (!username) {
    throw new Error("Username is required to unlock a user.");
  }

  try {
    return await apiRequest(
      "post",
      `/admin/Account/UnlockUser/${encodeURIComponent(username)}`
    );
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (username) => {
  if (!username) {
    throw new Error("Username is required to delete a user.");
  }
  return apiRequest("delete", `/admin/Account/DeleteUser?username=${username}`);
};

export const createRole = async (roleData) => {
  if (!roleData || !roleData.role) {
    throw new Error("Role data must include a role name.");
  }
  return apiRequest("post", "/admin/Account/CreateRole", roleData);
};

export const assignRole = async (roleData) => {
  if (!roleData || !roleData.username || !roleData.role) {
    throw new Error("Role data must include username and role name.");
  }
  return apiRequest("post", "/admin/Account/AssignRole", roleData);
};

export const getAllRoles = async () => {
  return apiRequest("get", "/admin/Account/GetAllRoles");
};

export const deleteRole = async (roleName) => {
  if (!roleName) {
    throw new Error("Role name is required to delete a role.");
  }

  try {
    return await apiRequest(
      "delete",
      `/admin/Account/DeleteRole?roleName=${encodeURIComponent(roleName)}`
    );
  } catch (error) {
    let errorMessage = "An error occurred while deleting the role.";

    if (error.response && error.response.data) {
      if (error.response.data.Message) {
        errorMessage = error.response.data.Message;
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (
        error.response.data.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        errorMessage = error.response.data.errors.join(", ");
      }
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Error in deleteRole:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const removeRoleFromUser = async (roleData) => {
  if (!roleData || !roleData.username || !roleData.role) {
    throw new Error("Role data must include a username and role name.");
  }

  try {
    return await apiRequest(
      "delete",
      `/admin/Account/RemoveRoleFromUser?username=${encodeURIComponent(
        roleData.username
      )}&role=${encodeURIComponent(roleData.role)}`
    );
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.errors?.join(", ") ||
      error.message ||
      "An error occurred while removing the role.";
    console.error("Error in removeRoleFromUser:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const changePassword = async (passwordData) => {
  if (
    !passwordData ||
    !passwordData.currentPassword ||
    !passwordData.newPassword
  ) {
    throw new Error("Password data must include current and new passwords.");
  }
  return apiRequest(
    "post",
    "/Account/ChangePassword/change-password",
    passwordData
  );
};

export const resetPassword = async (resetData) => {
  if (
    !resetData ||
    !resetData.email ||
    !resetData.token ||
    !resetData.newPassword
  ) {
    throw new Error("Reset data must include email, token, and new password.");
  }
  return apiRequest("post", "/Account/ResetPassword/reset-password", resetData);
};

export const confirmEmail = async (userId, code) => {
  if (!userId || !code) {
    throw new Error("User ID and code are required to confirm email.");
  }
  return apiRequest("get", "/Account/ConfirmEmail", null, {
    params: { userId, code },
  });
};

export const signIn = async (loginData) => {
  if (!loginData || !loginData.emailOrUsername || !loginData.password) {
    throw new Error("Login data must include email/username and password.");
  }
  return apiRequest("post", "/Account/SignIn", loginData);
};

// export const editSelf = async (userId, editData) => {
//   if (!editData) {
//     throw new Error("User data is required to edit self.");
//   }

//   return apiRequest("post", `/Account/EditSelf`, {
//     userId,
//     ...editData,
//   });
// };

export const forgotPassword = async (email) => {
  if (!email) {
    throw new Error("Email is required for password reset.");
  }
  return apiRequest("post", "/Account/ForgotPassword/forgot-password", {
    email,
  });
};
