import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCategory,
  getCategoryById,
  editCategory,
  setMainPhoto,
  deletePhotoFromCategory, 
} from "../../../api/categories";
import Notification from "../../../components/Authentification/Notification";
import { Close } from "@mui/icons-material";
import "./css/CategoryForm.css";

function CategoryForm() {
  const [category, setCategory] = useState({
    name: "",
    description: "",
    photos: [],
    isActive: true,
  });
  const [photoFiles, setPhotoFiles] = useState([]);
  const [existingPhotos, setExistingPhotos] = useState([]);
  const [mainPhotoIndex, setMainPhotoIndex] = useState(null);
  const [notification, setNotification] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    const totalFiles = files.length + existingPhotos.length + photoFiles.length;

    if (totalFiles > 10) {
      setNotification({
        messages: [
          `You can only upload a maximum of 10 photos. You tried to upload ${totalFiles}.`,
        ],
        type: "error",
      });
    } else {
      setPhotoFiles((prevFiles) => [...prevFiles, ...files]);
      if (files.length > 0 && mainPhotoIndex === null) {
        setMainPhotoIndex(existingPhotos.length);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setCategory({ ...category, isActive: e.target.checked });
  };

  const handleMainPhotoChange = async (e) => {
    const selectedPhotoIndex = parseInt(e.target.value, 10);

    if (selectedPhotoIndex !== mainPhotoIndex) {
      setMainPhotoIndex(selectedPhotoIndex);

      const selectedPhotoId =
        selectedPhotoIndex < existingPhotos.length
          ? existingPhotos[selectedPhotoIndex]?.id
          : null; 

      if (selectedPhotoId !== null && id) {
        try {
          await setMainPhoto(id, selectedPhotoId);
          setNotification({
            messages: ["Main photo updated successfully!"],
            type: "success",
          });
        } catch (error) {
          const errorMessage =
            error.response?.data?.Message || "Failed to update main photo.";
          setNotification({
            messages: [errorMessage],
            type: "error",
          });
          console.error("Error updating main photo:", error);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", category.name);
    formData.append("Description", category.description);
    formData.append("IsActive", category.isActive ? "true" : "false");

    if (id) {
      formData.append("Id", id);
    }

    photoFiles.forEach((file) => {
      formData.append("Photos", file, file.name);
    });

    try {
      setLoading(true);
      let createdCategory = null;
      let response;

      if (id) {
        response = await editCategory(id, formData);
      } else {
        response = await createCategory(formData);
      }

      if (response.success) {
        createdCategory = response.data;
        setNotification({
          messages: [
            id
              ? "Category updated successfully!"
              : "Category created successfully!",
          ],
          type: "success",
        });

        const mainPhotoId =
          mainPhotoIndex !== null && mainPhotoIndex < existingPhotos.length
            ? existingPhotos[mainPhotoIndex]?.id
            : createdCategory.photos[mainPhotoIndex - existingPhotos.length]
                ?.id;

        if (mainPhotoId !== null) {
          await setMainPhoto(createdCategory.id, mainPhotoId);
        } else if (!mainPhotoIndex && createdCategory.photos.length > 0) {
          const firstPhotoId = createdCategory.photos[0]?.id;
          await setMainPhoto(createdCategory.id, firstPhotoId);
        }

        setTimeout(() => {
          navigate("/admin/categories");
        }, 2000);
      } else {
        setNotification({
          messages: response.errors,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      async function fetchCategory() {
        try {
          const data = await getCategoryById(id);
          setCategory({
            name: data.name || "",
            description: data.description || "",
            isActive: data.isActive ?? true,
          });
          setExistingPhotos(data.photos || []);
          const mainPhotoIndex = data.photos.findIndex((photo) => photo.isMain);
          setMainPhotoIndex(mainPhotoIndex !== -1 ? mainPhotoIndex : null);
        } catch (error) {
          if (error.response) {
            setNotification({
              messages: ["Error fetching category data"],
              type: "error",
            });
            console.error("Error fetching category data", error.response?.data);
          }
        }
      }
      fetchCategory();
    }
  }, [id]);

  const handleBack = () => {
    navigate("/admin/categories");
  };

  const handleDeleteClick = (photoIndex) => {
    setPhotoToDelete(photoIndex);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPhotoToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (photoToDelete !== null) {
      if (photoToDelete < existingPhotos.length) {
        const photoId = existingPhotos[photoToDelete]?.id;

        if (id && photoId) {
          try {
            await deletePhotoFromCategory(id, photoId);
            const updatedPhotos = existingPhotos.filter(
              (_, index) => index !== photoToDelete
            );
            setExistingPhotos(updatedPhotos);

            if (photoToDelete === mainPhotoIndex) {
              setMainPhotoIndex(updatedPhotos.length ? 0 : null);
            }

            setNotification({
              messages: ["Photo deleted successfully!"],
              type: "success",
            });
          } catch (error) {
            setNotification({
              messages: ["Failed to delete photo from category."],
              type: "error",
            });
            console.error("Error deleting photo:", error);
          }
        }
      } else {
        const newPhotoIndex = photoToDelete - existingPhotos.length;
        const updatedPhotoFiles = photoFiles.filter(
          (_, index) => index !== newPhotoIndex
        );
        setPhotoFiles(updatedPhotoFiles);

        if (photoToDelete === mainPhotoIndex) {
          setMainPhotoIndex(updatedPhotoFiles.length ? 0 : null);
        }

        setNotification({
          messages: ["Photo deleted successfully!"],
          type: "success",
        });
      }
    }
    handleClose();
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {id ? "Edit Category" : "Create Category"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={category.name}
          onChange={handleInputChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={category.description}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={category.isActive}
              onChange={handleCheckboxChange}
              name="isActive"
              color="primary"
            />
          }
          label="Active"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handlePhotoChange}
          multiple
          style={{ margin: "10px 0" }}
          disabled={existingPhotos.length + photoFiles.length >= 10}
        />
        <FormHelperText>
          {existingPhotos.length + photoFiles.length >= 10
            ? "Maximum 10 photos allowed."
            : ""}
        </FormHelperText>
        <FormControl component="fieldset" margin="normal" required>
          <FormLabel component="legend">Select Main Photo</FormLabel>
          <RadioGroup
            row
            value={mainPhotoIndex ?? ""}
            onChange={handleMainPhotoChange}
          >
            {existingPhotos.map((photo, index) => (
              <FormControlLabel
                key={`existing-${index}`}
                value={index}
                control={<Radio color="primary" />}
                label={
                  <div style={{ position: "relative" }}>
                    <img
                      src={photo.url}
                      alt={`Existing ${photo.fileName}`}
                      width="50"
                      height="50"
                      style={{ margin: "5px" }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleDeleteClick(index)}
                      disabled={existingPhotos.length === 1}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </div>
                }
              />
            ))}
            {photoFiles.map((file, index) => (
              <FormControlLabel
                key={`new-${index}`}
                value={existingPhotos.length + index}
                control={<Radio color="primary" />}
                label={
                  <div style={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`New ${file.name}`}
                      width="50"
                      height="50"
                      style={{ margin: "5px" }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() =>
                        handleDeleteClick(existingPhotos.length + index)
                      }
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </div>
                }
              />
            ))}
          </RadioGroup>
          <FormHelperText>Select one photo as the main photo.</FormHelperText>
        </FormControl>
      </form>

      <div className="button-container">
        <Button
          variant="contained"
          className="styled-button styled-button-primary"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : id ? (
            "Update Category"
          ) : (
            "Create Category"
          )}
        </Button>
        <Button
          variant="outlined"
          className="styled-button styled-button-secondary"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>

      {notification.messages.length > 0 && (
        <Notification
          messages={notification.messages}
          type={notification.type}
        />
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this photo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CategoryForm;
