import React, { useEffect, useState } from "react";
import "./css/Notification.css";

const Notification = ({ messages, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, [messages]);

  const handleClose = () => {
    setVisible(false);
  };

  const notificationClass =
    type === "success" ? "notification-success" : "notification-error";

  return (
    visible && (
      <div className={`notification-container ${notificationClass}`}>
        {messages.map((message, index) => (
          <p key={index} className="notification-message">
            {message}
          </p>
        ))}
        <button className="notification-close-btn" onClick={handleClose}>
          &times;
        </button>
      </div>
    )
  );
};

export default Notification;
