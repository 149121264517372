import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCategoryById } from "../../api/auth";
import "./CategoryPage.css";

function CategoryPage() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const placesPerPage = 6;

  useEffect(() => {
    async function fetchCategory() {
      try {
        const data = await getCategoryById(categoryId);
        setCategory(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category", error);
        setLoading(false);
      }
    }

    fetchCategory();
  }, [categoryId]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredPlaces = category?.places.filter((place) =>
    place.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastPlace = currentPage * placesPerPage;
  const indexOfFirstPlace = indexOfLastPlace - placesPerPage;
  const currentPlaces = filteredPlaces?.slice(indexOfFirstPlace, indexOfLastPlace);

  const totalPages = Math.ceil(filteredPlaces?.length / placesPerPage);

  const handleClick = (number) => {
    setCurrentPage(number);
  };

  const handlePlaceClick = (placeId) => {
    navigate(`/places/${placeId}`);
  };

  if (loading) {
    return <div className="category-loading">Loading...</div>;
  }

  if (!category || !category.places || category.places.length === 0) {
    return (
      <div className="category-not-found">
        <h1>No places found</h1>
        <p>There are no places available in this category.</p>
      </div>
    );
  }

  return (
    <div className="category-page">
      <div className="category-info">
        <h1>{category.name}</h1>
        <p>{category.description}</p>
      </div>

      <input
        type="text"
        placeholder="Search places..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-box"
      />

      <div className="places-grid">
        {currentPlaces.length > 0 ? (
          currentPlaces.map((place) => (
            <div
              key={place.id}
              className="place-card"
              onClick={() => handlePlaceClick(place.id)}
            >
              <img
                src={place.photos[0]?.url}
                alt={place.name}
                className="place-image"
              />
              <div className="place-info">
                <h3>{place.name}</h3>
                <p>{place.description}</p>
                <p className="place-address">{place.address}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No places match your search criteria.</p>
        )}
      </div>

      <div className="pagination">
        {[...Array(totalPages).keys()].map((number) => (
          <button
            key={number + 1}
            onClick={() => handleClick(number + 1)}
            className={`page-item ${currentPage === number + 1 ? "active" : ""}`}
          >
            {number + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;