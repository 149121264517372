import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { createSlider, getSliderById, editSlider } from "../../../api/sliders";
import Notification from "../../../components/Authentification/Notification";
import { Close } from "@mui/icons-material";

function SliderForm() {
  const [slider, setSlider] = useState({
    title: "",
    description: "",
    imageUrl: "",
  });
  const [initialSlider, setInitialSlider] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [notification, setNotification] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSlider({ ...slider, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (slider.title !== initialSlider?.title) {
      formData.append("Title", slider.title);
    }
    if (slider.description !== initialSlider?.description) {
      formData.append("Description", slider.description);
    }
    if (imageFile) {
      formData.append("Image", imageFile, imageFile.name);
    }

    try {
      setLoading(true);
      let response;

      if (id) {
        response = await editSlider(id, formData);
      } else {
        response = await createSlider(formData);
      }

      if (response.success) {
        setNotification({
          messages: [
            id
              ? "Slider updated successfully!"
              : "Slider created successfully!",
          ],
          type: "success",
        });

        setTimeout(() => {
          navigate("/admin/sliders");
        }, 2000);
      } else {
        setNotification({
          messages: response.errors,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      async function fetchSlider() {
        try {
          const data = await getSliderById(id);
          setSlider({
            title: data.title || "",
            description: data.description || "",
            imageUrl: data.imageUrl || "",
          });
          setInitialSlider(data); 
        } catch (error) {
          if (error.response) {
            setNotification({
              messages: ["Error fetching slider data"],
              type: "error",
            });
            console.error("Error fetching slider data", error.response?.data);
          }
        }
      }
      fetchSlider();
    }
  }, [id]);

  const handleBack = () => {
    navigate("/admin/sliders");
  };

  const handleDeleteImage = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    setSlider((prevState) => ({ ...prevState, imageUrl: "" }));
    setImageFile(null);
    handleClose();
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {id ? "Edit Slider" : "Create Slider"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          name="title"
          value={slider.title}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={slider.description}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ margin: "10px 0" }}
        />
        <FormHelperText>
          {slider.imageUrl && !imageFile ? (
            <div style={{ position: "relative" }}>
              <img
                src={slider.imageUrl}
                alt="Slider"
                style={{ width: "100%", height: "auto", marginBottom: "10px" }}
              />
              <IconButton
                size="small"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                }}
                onClick={handleDeleteImage}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
          ) : null}
        </FormHelperText>
      </form>

      <div className="button-container">
        <Button
          variant="contained"
          className="styled-button styled-button-primary"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : id ? (
            "Update Slider"
          ) : (
            "Create Slider"
          )}
        </Button>
        <Button
          variant="outlined"
          className="styled-button styled-button-secondary"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>

      {notification.messages.length > 0 && (
        <Notification
          messages={notification.messages}
          type={notification.type}
        />
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SliderForm;
