import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/admin/Slider";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

// Get all sliders
export const getAllSliders = async () => {
  try {
    const response = await axios.get(`${API_URL}/GetAll`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sliders", error);
    throw new Error("Failed to fetch sliders");
  }
};

// Get slider by ID
export const getSliderById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/GetById/${id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching slider by ID", error);
    throw new Error("Failed to fetch slider details");
  }
};

// Create a new slider
export const createSlider = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/Create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getAuthHeader(),
      },
    });

    if (response.status === 201 || response.status === 200) {
      console.log("Slider created successfully", response.data);
      return {
        success: true,
        data: response.data,
      };
    } else {
      console.error("Unexpected response status:", response.status);
      return {
        success: false,
        errors: ["Unexpected response status"],
      };
    }
  } catch (error) {
    const errorMessages = error.response?.data?.errors
      ? Object.entries(error.response.data.errors).flatMap(([field, errors]) =>
          errors.map((error) => `${field}: ${error}`)
        )
      : [error.response?.data?.message || "Error processing request"];

    console.error("Error creating slider", errorMessages);

    return {
      success: false,
      errors: errorMessages,
    };
  }
};

// Edit an existing slider
export const editSlider = async (sliderId, formData) => {
  try {
    formData.append("Id", sliderId);

    const response = await axios.put(
      `${API_URL}/Update/${sliderId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          ...getAuthHeader(),
        },
      }
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessages = error.response?.data?.errors
      ? Object.entries(error.response.data.errors).flatMap(([field, errors]) =>
          errors.map((error) => `${field}: ${error}`)
        )
      : [error.response?.data?.message || "Error processing request"];

    console.error("Error editing slider", errorMessages);

    return {
      success: false,
      errors: errorMessages,
    };
  }
};

// Delete a slider
export const deleteSlider = async (sliderId) => {
  try {
    const response = await axios.delete(`${API_URL}/Delete/${sliderId}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    let errorMessage =
      "Cannot delete slider items. A minimum of 3 slider items must be maintained.";
    if (error.response && error.response.data) {
      errorMessage =
        error.response.data.message ||
        error.response.data.error ||
        errorMessage;
    }
    console.error(errorMessage, error);

    throw new Error(errorMessage);
  }
};
