import React, { useState } from "react";
import { register } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Notification from "./Notification";
import { CircularProgress, Typography } from "@mui/material";
import "./css/Register.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);  
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await register(username, email, fullName, password);
      setSuccessMessage(
        "Registration successful! Please check your email to confirm your account."
      );

      setTimeout(() => {
        setLoading(false);
        navigate("/login");
      }, 3000);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        const { status, data } = error.response;
        let errorMessages = [];

        switch (status) {
          case 400:
            if (data.errors) {
              errorMessages = Object.values(data.errors).flat();
            } else {
              errorMessages.push(data.message || "Bad request.");
            }
            break;
          case 401:
            errorMessages.push("Unauthorized: Invalid email or password.");
            break;
          case 403:
            errorMessages.push("Forbidden: You do not have access.");
            break;
          case 404:
            errorMessages.push("Not found: Requested resource not found.");
            break;
          case 409:
            errorMessages.push("Conflict: Email or username already in use.");
            break;
          case 500:
            errorMessages.push("Internal Server Error: Please try again later.");
            break;
          default:
            errorMessages.push("An unknown error occurred.");
            break;
        }
        setErrors(errorMessages);
      } else {
        setErrors(["Network error: Please check your internet connection."]);
      }
    }
  };

  return (
    <div className="register-container">
      {errors.length > 0 && <Notification messages={errors} type="error" />}
      {successMessage && (
        <Notification messages={[successMessage]} type="success" />
      )}
      {loading && (
        <div className="loading-container">
          <CircularProgress />
          <Typography variant="body1" align="center">
            Redirecting to login...
          </Typography>
        </div>
      )}
      {!loading && (
        <form onSubmit={handleRegister} className="register-form">
          <div className="form-group slide-in-left">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group slide-in-right">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group slide-in-left">
            <label>Full Name</label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="form-group slide-in-right">
            <label>Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? "🙈" : "👁️"}
              </button>
            </div>
          </div>
          <button type="submit" className="submit-button">
            Register
          </button>
        </form>
      )}
    </div>
  );
};

export default Register;
