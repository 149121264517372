import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LockOpen as LockOpenIcon,
  Details as DetailsIcon,
  PersonAdd as PersonAddIcon,
  RemoveCircle as RemoveCircleIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import "./css/UserTable.css";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const UserTable = ({
  users,
  paginatedUsers,
  handleEditUserDialogOpen,
  handleAssignRoleDialogOpen,
  handleRemoveRoleDialogOpen,
  handleUnlockUser,
  handleGetUserByUsername,
  handleDeleteDialogOpen,
  isLoading,
  isAdmin,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const sortedUsers = [...paginatedUsers].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key].toLowerCase();
      const bValue = b[sortConfig.key].toLowerCase();

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? (
        <ArrowUpwardIcon className="sort-icon active" />
      ) : (
        <ArrowDownwardIcon className="sort-icon active" />
      );
    } else {
      return <ArrowUpwardIcon className="sort-icon" />;
    }
  };

  return (
    <TableContainer component={Paper} className="table-container">
      <Table className="table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell className={`table-head-cell ${sortConfig.key === 'username' ? 'active' : ''}`} onClick={() => handleSort('username')}>
              Username {getSortIcon('username')}
            </TableCell>
            <TableCell className={`table-head-cell ${sortConfig.key === 'email' ? 'active' : ''}`} onClick={() => handleSort('email')}>
              Email {getSortIcon('email')}
            </TableCell>
            <TableCell className={`table-head-cell ${sortConfig.key === 'fullName' ? 'active' : ''}`} onClick={() => handleSort('fullName')}>
              Full Name {getSortIcon('fullName')}
            </TableCell>
            <TableCell className="table-head-cell">Roles</TableCell>
            <TableCell className="table-head-cell">Email Confirmed</TableCell>
            <TableCell className={`table-head-cell ${sortConfig.key === 'createdDate' ? 'active' : ''}`} onClick={() => handleSort('createdDate')}>
              Created Date {getSortIcon('createdDate')}
            </TableCell>
            <TableCell className="table-head-cell">Last Password Reset</TableCell>
            <TableCell className="table-head-cell">Password Reset Count</TableCell>
            <TableCell className="table-head-cell">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map((user) => (
            <TableRow key={user.username} className="table-body-row">
              <TableCell className="table-cell">{user.username}</TableCell>
              <TableCell className="table-cell">{user.email}</TableCell>
              <TableCell className="table-cell">{user.fullName}</TableCell>
              <TableCell className="table-cell">
                {user.roles && user.roles.length > 0
                  ? user.roles.join(", ")
                  : "No roles"}
              </TableCell>
              <TableCell className="table-cell">
                {user.isEmailConfirmed ? "Yes" : "No"}
              </TableCell>
              <TableCell className="table-cell">
                {formatDate(user.createdDate)}
              </TableCell>
              <TableCell className="table-cell">
                {user.lastPasswordResetRequest
                  ? formatDate(user.lastPasswordResetRequest)
                  : "Never"}
              </TableCell>
              <TableCell className="table-cell">
                {user.passwordResetRequestCount}
              </TableCell>
              <TableCell className="table-cell action-buttons">
                <div className="action-buttons-container">
                  {isAdmin && (
                    <Tooltip title="Edit User Profile">
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        className="button edit-user full-width-button"
                        onClick={() => handleEditUserDialogOpen(user)}
                        color="primary"
                      >
                        Edit User
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Assign a Role to User">
                    <Button
                      variant="outlined"
                      startIcon={<PersonAddIcon />}
                      className="button assign-role full-width-button"
                      onClick={() => handleAssignRoleDialogOpen(user.username)}
                      color="secondary"
                    >
                      Assign Role
                    </Button>
                  </Tooltip>
                  <Tooltip title="Remove a Role from User">
                    <Button
                      variant="outlined"
                      startIcon={<RemoveCircleIcon />}
                      className="button remove-role full-width-button"
                      onClick={() => handleRemoveRoleDialogOpen(user.username)}
                      disabled={isLoading}
                      color="error"
                    >
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Remove Role"
                      )}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Unlock User">
                    <Button
                      variant="outlined"
                      startIcon={<LockOpenIcon />}
                      className="button unlock-user full-width-button"
                      onClick={() => handleUnlockUser(user.username)}
                      color="warning"
                    >
                      Unlock
                    </Button>
                  </Tooltip>
                  <Tooltip title="Get User Details">
                    <Button
                      variant="outlined"
                      startIcon={<DetailsIcon />}
                      className="button get-details full-width-button"
                      onClick={() => handleGetUserByUsername(user.username)}
                      color="info"
                    >
                      Details
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete User Account">
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteDialogOpen(user.username)}
                      aria-label={`delete-user-${user.username}`}
                      className="button delete-user full-width-button"
                      color="error"
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
