import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../api/auth";
import { ClipLoader } from "react-spinners";
import "./Categories.css";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCategories() {
      try {
        const data = await getAllCategories();
        setCategories(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories", error);
        setLoading(false);
      }
    }

    fetchCategories();
  }, []);

  const handleCategoryClick = (id) => {
    navigate(`/categories/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortClick = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredCategories = categories
    .filter((category) =>
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });

  return (
    <div className="categories-page">
      <h1 className="categories-title">All Categories</h1>
      <div className="search-sort-container">
        <input
          type="text"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <button onClick={handleSortClick} className="sort-button">
          Sort by Name&nbsp;
          {sortOrder === "asc" ? <FaArrowUp /> : <FaArrowDown />}
        </button>
      </div>
      {loading ? (
        <div className="loader-container">
          <ClipLoader color="#007bff" size={50} />
        </div>
      ) : (
        <div className="categories-list">
          {filteredCategories.map((category) => (
            <div
              key={category.id}
              className="category-item"
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={
                  category.photos.find((photo) => photo.isMain)?.url ||
                  category.photos[0].url
                }
                alt={category.name}
                className="category-image"
              />
              <h3>{category.name}</h3>
              <p>{category.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Categories;