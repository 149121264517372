import React from "react";

function Contact() {
  return (
    <div>
      <h1>Contact Page</h1>
      <p>Welcome to the Contact page!</p>
    </div>
  );
}

export default Contact;
