import React from "react";
import { Container, Typography, Grid, Box, Paper } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Иконка для маркера на карте
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});

// Координаты для центра карты
const center = {
  lat: 40.4093,
  lng: 49.8671,
};

function About() {
  return (
    <Container sx={{ mt: 10 }}>
      {" "}
      {/* Добавлен отступ сверху */}
      <Typography variant="h4" gutterBottom align="center">
        About Us
      </Typography>
      <Typography variant="body1" paragraph align="center" marginBottom={4}>
        Welcome to our company. We are dedicated to providing the best services
        to our clients. Here you can find more information about us and our
        location.
      </Typography>
      <Grid container spacing={4} marginBottom={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Box>
              <Typography variant="body1">
                <strong>Address:</strong> 123 Main St, Baku, Azerbaijan
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> (+994) 12 123-4567
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> contact@ourcompany.com
              </Typography>
              <Typography variant="body1">
                <strong>Working Hours:</strong> Mon-Fri, 9:00 AM - 5:00 PM
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <MapContainer
              center={center}
              zoom={13}
              style={{ height: "400px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={center} icon={customIcon}>
                <Popup>
                  Our Company Location
                  <br /> Baku, Azerbaijan.
                </Popup>
              </Marker>
            </MapContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
