import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./css/RegistrationSuccess.css";

const RegistrationSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 4000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="registration-success">
      <h1>Registration Successful</h1>
      <p>
        Your registration has been successfully confirmed. You will be
        redirected to the login page shortly.
      </p>
    </div>
  );
};

export default RegistrationSuccess;
