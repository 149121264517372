import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/admin/Category";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/GetAll`, {
      headers: getAuthHeader(),
    });

    await Promise.all(
      response.data.map(async (category) => {
        try {
          await createCategoryPage(category.id, category.name);
        } catch (error) {
          console.error(
            `Failed to create page for category ${category.name}`,
            error
          );
        }
      })
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching categories", error);
    throw new Error("Failed to fetch categories");
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/GetById/${id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching category by ID", error);
    throw new Error("Failed to fetch category details");
  }
};

export const createCategory = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/Create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getAuthHeader(),
      },
    });

    console.log("Response from server:", response.data);

    if (response.status === 201 || response.status === 200) {
      const createdCategoryId = response.data.id;
      const createdCategoryName = response.data.name;

      if (!createdCategoryId) {
        console.error(
          "Server response does not contain a valid category ID:",
          response.data
        );
        throw new Error("Invalid category ID returned from server");
      }

      console.log("Category created with ID:", createdCategoryId);

      await createCategoryPage(createdCategoryId, createdCategoryName);
      return {
        success: true,
        data: response.data,
      };
    } else {
      console.error("Unexpected response status:", response.status);
      return {
        success: false,
        errors: ["Unexpected response status"],
      };
    }
  } catch (error) {
    const errorMessages = error.response?.data?.errors
      ? Object.entries(error.response.data.errors).flatMap(([field, errors]) =>
          errors.map((error) => `${field}: ${error}`)
        )
      : [error.response?.data?.message || "Error processing request"];

    console.error("Error creating category", errorMessages);

    return {
      success: false,
      errors: errorMessages,
    };
  }
};

export const createCategoryPage = async (categoryId, categoryName) => {
  if (!categoryId || typeof categoryId !== "number") {
    console.error("Invalid category ID:", categoryId);
    throw new Error("Invalid category ID");
  }

  try {
    const response = await axios.post(
      `${API_URL}/CreateCategoryPage`,
      {
        categoryId,
        categoryName,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeader(),
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating category page", error);

    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }

    throw new Error("Failed to create category page");
  }
};

export const editCategory = async (categoryId, formData) => {
  try {
    formData.append("Id", categoryId);

    const response = await axios.put(
      `${API_URL}/Edit/${categoryId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          ...getAuthHeader(),
        },
      }
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    const errorMessages = error.response?.data?.errors
      ? Object.entries(error.response.data.errors).flatMap(([field, errors]) =>
          errors.map((error) => `${field}: ${error}`)
        )
      : [error.response?.data?.message || "Error processing request"];

    console.error("Error editing category", errorMessages);

    return {
      success: false,
      errors: errorMessages,
    };
  }
};

export const setMainPhoto = async (categoryId, photoId) => {
  try {
    const response = await axios.put(
      `${API_URL}/SetMainPhoto/set-main-photo`,
      {
        categoryId,
        photoId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeader(),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error setting main photo",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await axios.delete(`${API_URL}/Delete/${categoryId}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    let errorMessage =
      "Cannot delete category. A minimum of 3 categories must be maintained";
    if (error.response && error.response.data) {
      errorMessage =
        error.response.data.message ||
        error.response.data.error ||
        errorMessage;
    }
    console.error(errorMessage, error);

    throw new Error(errorMessage);
  }
};

export const deletePhotoFromCategory = async (categoryId, photoId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/DeletePhoto/${categoryId}/photos/${photoId}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting photo from category", error);
    throw new Error("Error deleting photo from category");
  }
};
