import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/Search/Search";

export const searchQuery = async (query) => {
  try {
    const response = await axios.post(
      API_URL,
      { query },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching search results", error);
    throw error;
  }
};
