import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getBookingById, updateBooking } from "../../../api/bookings";

function BookingForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState({
    userName: "",
    email: "",
    phone: "",
    bookingDate: new Date(), 
    isConfirmed: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchBooking() {
      try {
        const data = await getBookingById(id);
        data.bookingDate = new Date(data.bookingDate); 
        setBookingData(data);
      } catch (error) {
        console.error("Error fetching booking details", error);
      } finally {
        setLoading(false);
      }
    }

    fetchBooking();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value });
  };

  const handleDateChange = (newDate) => {
    setBookingData({ ...bookingData, bookingDate: newDate });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateBooking(id, bookingData);
      navigate("/admin/bookings");
    } catch (error) {
      console.error("Error updating booking", error);
      alert("Failed to update booking");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Edit Booking
      </Typography>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="User Name"
            name="userName"
            value={bookingData.userName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Email"
            name="email"
            value={bookingData.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Phone"
            name="phone"
            value={bookingData.phone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Booking Date"
              value={bookingData.bookingDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2, color: "white" }}
          >
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mt: 2, ml: 2 }}
            onClick={() => navigate("/admin/bookings")}
          >
            Cancel
          </Button>
        </form>
      )}
    </Container>
  );
}

export default BookingForm;
