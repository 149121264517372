import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { getCurrentUser, changePassword } from "../../api/auth"; // Импортируем функции из auth.js
import axios from "axios";

const MyAccount = () => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    fullName: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = getCurrentUser(); // Получаем текущего пользователя из токена
      if (!currentUser || !currentUser.username) {
        setMessage("User not found");
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/Account/GetUserByUsername/${currentUser.username}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Failed to fetch user data", error);
        setMessage("Failed to fetch user data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      setIsLoading(true);
      await changePassword(userData.username, oldPassword, newPassword); // Смена пароля с использованием auth.js
      setMessage("Password changed successfully");
    } catch (error) {
      setMessage("Failed to change password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        My Account
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Avatar
              alt={userData.fullName}
              src="/broken-image.jpg"
              sx={{ width: 100, height: 100, margin: "auto" }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              label="Full Name"
              value={userData.fullName}
              fullWidth
              disabled
              margin="normal"
            />
            <TextField
              label="Username"
              value={userData.username}
              fullWidth
              disabled
              margin="normal"
            />
            <TextField
              label="Email"
              value={userData.email}
              fullWidth
              disabled
              margin="normal"
            />

            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>

            <TextField
              label="Current Password"
              type="password"
              fullWidth
              margin="normal"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />

            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={passwordError !== ""}
              helperText={passwordError}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handlePasswordChange}
              disabled={isLoading}
              sx={{ mt: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Change Password"}
            </Button>
            {message && (
              <Typography
                color={message.includes("successfully") ? "primary" : "error"}
                sx={{ mt: 2 }}
              >
                {message}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default MyAccount;
