import React, { useEffect, useRef, useState } from "react";
import Slider from "./Slider/Slider";
import Typography from "@mui/material/Typography";
import "./Home.css";
import { getAllCategories } from "../../../src/api/categories"; 
import { useNavigate } from "react-router-dom";

function Home() {
  const homeContentRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getAllCategories();
        const shuffledCategories = shuffleArray(data); 
        setCategories(shuffledCategories.slice(0, 3)); 
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.5 }
    );

    const elements =
      homeContentRef.current.querySelectorAll(".animate-on-scroll");

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const handleCategoryClick = (id) => {
    navigate(`/categories/${id}`);
  };

  return (
    <div>
      <Slider />

      <div className="home-content" ref={homeContentRef}>
        <Typography
          variant="h2"
          className="animate-on-scroll"
          gutterBottom
          component="h1"
          sx={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: "bold",
            color: "primary.main",
          }}
        >
          Home Page
        </Typography>
        <br></br>
        <Typography
          variant="h5"
          className="animate-on-scroll"
          sx={{
            fontFamily: "Roboto, sans-serif",
            fontStyle: "italic",
            color: "text.secondary",
          }}
        >
          BookItAll: Your One-Stop Destination for Booking Everything!
        </Typography>
      </div>

      <div className="categories">
        <Typography variant="h4" gutterBottom>
          Categories
        </Typography>
        <div className="categories-list">
          {categories.map((category) => (
            <div
              key={category.id}
              className="category-item"
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={
                  category.photos.find((photo) => photo.isMain)?.url ||
                  "https://via.placeholder.com/600x300?text=No+Image"
                }
                alt={category.name}
                className="category-image"
              />
              <Typography variant="h6">{category.name}</Typography>
              <Typography variant="body2">{category.description}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;