import axios from "axios";

const API_URL =
  "https://bookitall-h8erbggybhejfmh3.eastus-01.azurewebsites.net/api/Place";

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export const getAllPlaces = async () => {
  try {
    const response = await axios.get(`${API_URL}/GetAll`, {
      headers: getAuthHeader(),
    });
    if (response.data.length === 0) {
      return [];
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching places",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to fetch places");
  }
};

export const getPlaceById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/GetById/${id}`, {
      headers: getAuthHeader(),
    });

    if (!response.data || Object.keys(response.data).length === 0) {
      throw new Error("Place not found or data is empty");
    }

    return response.data;
  } catch (error) {
    console.error(
      "Error fetching place by ID",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to fetch place details");
  }
};

export const createPlace = async (placeData) => {
  try {
    const response = await axios.post(`${API_URL}/Create`, placeData, {
      headers: getAuthHeader(),
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    throw error;
  }
};

export const editPlace = async (id, placeData) => {
  try {
    const response = await axios.put(`${API_URL}/Edit/${id}`, placeData, {
      headers: getAuthHeader(),
    });
    return { status: response.status, data: response.data };
  } catch (error) {
    throw error;
  }
};

export const deletePlace = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/Delete/${id}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting place", error);
    throw new Error("Failed to delete place");
  }
};

export const setMainPhoto = async (placeId, photoId) => {
  try {
    const response = await axios.post(
      `${API_URL}/SetMainPhoto`,
      { placeId, photoId },
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error setting main photo", error);
    throw new Error("Failed to set main photo");
  }
};

export const deletePhotoFromPlace = async (placeId, photoId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/DeletePhoto/${placeId}/photos/${photoId}`,
      {
        headers: getAuthHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting photo", error);
    throw new Error("Failed to delete photo");
  }
};
