import React, { useState } from "react";
import { forgotPassword } from "../../api/auth";
import Notification from "./Notification";
import "./css/ForgotPassword.css";
import { CircularProgress } from "@mui/material";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState("success");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await forgotPassword(email);
      setMessages([
        "Password reset instructions have been sent to your email.",
      ]);
      setMessageType("success");
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.data) {
        const { status, data } = error.response;
        let errorMessages = [];

        if (data.errors) {
          errorMessages = Object.values(data.errors).flat();
        } else {
          switch (status) {
            case 400:
              errorMessages.push(data.message || "Bad request. Please check the entered data.");
              break;
            case 401:
              errorMessages.push("Unauthorized: Invalid email.");
              break;
            case 403:
              errorMessages.push("Forbidden: You do not have access.");
              break;
            case 404:
              errorMessages.push("Not found: Requested resource not found.");
              break;
            case 409:
              errorMessages.push("Conflict: This email is associated with multiple accounts.");
              break;
            case 500:
              errorMessages.push("Internal Server Error: Please try again later.");
              break;
            default:
              errorMessages.push("An unknown error occurred.");
              break;
          }
        }

        setMessages(errorMessages);
        setMessageType("error");
      } else {
        setMessages(["Network error: Please check your internet connection."]);
        setMessageType("error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      {loading && (
        <div className="loading-overlay">
          <CircularProgress size={80} />
        </div>
      )}
      {messages.length > 0 && (
        <Notification messages={messages} type={messageType} />
      )}
      <form onSubmit={handleForgotPassword} className="forgot-password-form">
        <h2>Forgot Password</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
        </div>
        <button type="submit" disabled={loading}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
