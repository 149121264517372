import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Login from "./components/Authentification/Login";
import Register from "./components/Authentification/Register";
import ForgotPassword from "./components/Authentification/ForgotPassword";
import Categories from "./pages/Categories/Categories";
import AdminPanel from "./pages/Admin/AdminPanel";
import RegistrationSuccess from "./components/Authentification/RegistrationSuccess";
import ResetPassword from "./components/Authentification/ResetPassword";
import UserManagement from "./pages/Admin/AdminManagement/UserManagement/UserManagement";
import CategoryPage from "./pages/Categories/CategoryPage";
import CategoryManagement from "./pages/Admin/AdminManagement/CategoryManagement";
import CategoryForm from "./pages/Admin/AdminManagement/CategoryForm";
import SliderManagement from "./pages/Admin/AdminManagement/SliderManagement";
import SliderForm from "./pages/Admin/AdminManagement/SliderForm";
import PlaceManagement from "./pages/Admin/AdminManagement/PlaceManagement";
import PlaceForm from "./pages/Admin/AdminManagement/PlaceForm";
import PlacePage from "./pages/Places/PlacePage";
import BookingManagement from "./pages/Admin/AdminManagement/BookingManagement";
import BookingForm from "./pages/Admin/AdminManagement/BookingForm";
import MyAccount from "./components/Header/MyAccount";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <div className="container mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/all-categories" element={<Categories />} />
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/categories/:categoryId" element={<CategoryPage />} />
            <Route path="/admin/categories" element={<CategoryManagement />} />
            <Route path="/admin/categories/create" element={<CategoryForm />} />
            <Route
              path="/admin/categories/edit/:id"
              element={<CategoryForm />}
            />
            <Route path="/admin/sliders" element={<SliderManagement />} />
            <Route path="/admin/sliders/create" element={<SliderForm />} />
            <Route path="/admin/sliders/edit/:id" element={<SliderForm />} />
            <Route path="/admin/places" element={<PlaceManagement />} />
            <Route path="/admin/places/create" element={<PlaceForm />} />
            <Route path="/admin/places/edit/:id" element={<PlaceForm />} />
            <Route path="/places/:id" element={<PlacePage />} />
            <Route path="/admin/bookings" element={<BookingManagement />} />
            <Route path="/admin/bookings/edit/:id" element={<BookingForm />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route
              path="/registration-success"
              element={<RegistrationSuccess />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
