import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import {
  Facebook,
  Twitter,
  Instagram,
  Email,
  Phone,
} from "@mui/icons-material";

function Footer() {
  return (
    <div className="container">
      <footer className="py-5">
        <div className="row">
          <div className="col-12 col-md-3 mb-3">
            <h5>Contact Us</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="mailto:support@bookitall.online"
                  className="nav-link p-0 text-muted"
                >
                  <Email className="me-2" /> support@bookitall.online
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="tel:+1234567890" className="nav-link p-0 text-muted">
                  <Phone className="me-2" /> +1 234 567 890
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-5 offset-md-1 mb-3 custom-newsletter-form">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>Monthly digest of what's new and exciting from us.</p>
              <div className="d-flex flex-column flex-sm-row w-100 gap-2 subscribe-form-container">
                <label htmlFor="newsletter1" className="visually-hidden">
                  Email address
                </label>
                <input
                  id="newsletter1"
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                />
                <button className="subscribe-button" type="button">
                  <span>Subscribe</span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
          <p>© 2024 BookItAll Company, Inc. All rights reserved.</p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3">
              <a className="link-dark" href="https://twitter.com">
                <Twitter />
              </a>
            </li>
            <li className="ms-3">
              <a className="link-dark" href="https://instagram.com">
                <Instagram />
              </a>
            </li>
            <li className="ms-3">
              <a className="link-dark" href="https://facebook.com">
                <Facebook />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
