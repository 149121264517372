import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { getAllBookings, deleteBooking } from "../../../api/bookings";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./css/BookingManagement.css";

function BookingManagement() {
  const [bookings, setBookings] = useState([]);  
  const [loading, setLoading] = useState(true);  
  const navigate = useNavigate(); 

  useEffect(() => {
    async function fetchBookings() {
      try {
        const data = await getAllBookings(); 
        setBookings(data);  
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);  
      }
    }

    fetchBookings();  
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this booking?")) {
      try {
        await deleteBooking(id);  
        setBookings(bookings.filter((booking) => booking.id !== id)); 
      } catch (error) {
        console.error("Error deleting booking:", error);
        alert("Failed to delete booking");
      }
    }
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Booking Management
      </Typography>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={4}>
          {bookings.map((booking) => (
            <Grid item xs={12} md={6} key={booking.id}>
              <Paper
                elevation={3}
                className="booking-card"
                sx={{
                  padding: "20px",
                  borderRadius: "10px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Booking ID: {booking.id}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Place: {booking.placeName}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  User: {booking.userName}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Email: {booking.email}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Phone: {booking.phone}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Date: {new Date(booking.bookingDate).toLocaleString()}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Status: {booking.isConfirmed ? "Confirmed" : "Not Confirmed"}
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                  <IconButton
                    color="primary"
                    onClick={() => navigate(`/admin/bookings/edit/${booking.id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(booking.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default BookingManagement;
